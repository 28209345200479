import brand from '@helpers/brand';
import srOnly from '@helpers/srOnly';
import { rgba } from 'polished';
import styled from 'styled-components';
const Input = styled.input `
  ${srOnly}
`;
const Label = styled.label `
  position: relative;
  padding-left: 28px;
  user-select: none;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 3px;
    height: 20px;
    width: 20px;
    transition: all 0.1s;
  }

  &::before {
    display: inline-flex;
    background: ${brand.white};
    border: 1px solid ${rgba(brand.grey.grey55, 0.75)};
    place-content: center;
    place-items: center;
    text-align: center;
  }

  &::after {
    display: inline-flex;
    background: ${brand.primary.base};
    background-clip: content-box;
    border: 1px solid ${rgba(brand.grey.grey55, 0.75)};
    border-radius: 3px;
    padding: 3px;
    place-content: center;
    place-items: center;
    text-align: center;
    opacity: 0;
    width: 14px;
    height: 14px;
  }

  ${Input}:checked + &::after {
    opacity: 1;
  }
`;
export default {
    Input,
    Label,
};
