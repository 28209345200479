import { useState } from 'react';
import useIntersectionObserver from './useIntersectionObserver';
export function useInView(options, reverse = false, isEditMode = false) {
    const [inView, setInView] = useState(false);
    const { elementRef } = useIntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
            setInView(true);
        }
        else if (reverse === true) {
            setInView(false);
        }
    }, options);
    return [elementRef, isEditMode ? true : inView];
}
