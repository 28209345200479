import { isSSR } from '@helpers/ssr';
function load() {
    const promise = new Promise((resolve, reject) => {
        if (isSSR() || typeof window.grecaptcha !== 'undefined') {
            return resolve();
        }
        try {
            const script = document.createElement('script');
            window.onRecaptchaLoad = function onRecaptchaLoad() {
                resolve();
            };
            script.src = 'https://www.google.com/recaptcha/api.js?onload=onRecaptchaLoad&render=explicit';
            document.head.appendChild(script);
        }
        catch (error) {
            reject(error);
        }
    });
    return promise;
}
function initialise(element, sitekey, callback) {
    const promise = new Promise((resolve, reject) => {
        if (typeof window.grecaptcha === 'undefined') {
            return reject(`"window.grecaptcha" is undefined, ensure the recaptcha script has been loaded.`);
        }
        window.grecaptcha.ready(() => {
            const widgetId = window.grecaptcha.render(element, {
                sitekey: sitekey,
                callback: callback,
                'expired-callback': () => {
                    window.grecaptcha.reset('recaptcha-container');
                },
                'error-callback': reject,
            });
            resolve(widgetId);
        });
    });
    return promise;
}
function reset(widgetId) {
    const promise = new Promise((resolve, reject) => {
        if (typeof window.grecaptcha === 'undefined') {
            return reject(`"window.grecaptcha" is undefined, ensure the recaptcha script has been loaded.`);
        }
        window.grecaptcha.ready(() => {
            window.grecaptcha.reset(widgetId);
            resolve();
        });
    });
    return promise;
}
export { initialise, load, reset };
