import { useInView } from '@hooks/useInView';
import { useRecaptcha } from '@hooks/useRecaptcha';
import * as React from 'react';
import S from './Recaptcha.styles';
const Recaptcha = ({ shouldLoad, siteKey, onChange }) => {
    const [ref, inView] = useInView({ threshold: 1 });
    const recaptcha = useRecaptcha({
        shouldLoad: inView ? shouldLoad : false,
        siteKey: siteKey,
        verifyCallback: (token) => {
            onChange(token);
        },
    });
    return React.createElement(S.Container, { ref: ref }, inView && React.createElement(S.Recaptcha, { ref: recaptcha.ref }));
};
export default React.memo(Recaptcha);
