import * as recaptcha from '@helpers/recaptcha';
import * as React from 'react';
export function useRecaptcha(params) {
    const ref = React.useRef(null);
    const [recaptchaState, setRecaptchaState] = React.useState({ widgetId: '' });
    // Handle initialisation of recaptcha.
    React.useEffect(() => {
        doAsync();
        async function doAsync() {
            // Defer until we should load, and ref is set.
            if (!ref.current || !params.shouldLoad) {
                return;
            }
            // Load recaptcha script.
            await recaptcha.load();
            // Initialise the recaptcha widget and store widget id in state.
            const widgetId = await recaptcha.initialise(ref.current, params.siteKey, params.verifyCallback);
            setRecaptchaState({ widgetId });
        }
    }, [params.shouldLoad]);
    // Create callback function based on current widget id.
    const reset = React.useCallback(async () => {
        await recaptcha.reset(recaptchaState.widgetId);
    }, [recaptchaState.widgetId]);
    return { ...recaptchaState, ref, reset };
}
