import { isSSR } from '@helpers/ssr';
import querystring from 'querystring';
class ApiService {
    async request(request) {
        // Get the url
        const url = this.getUrl(request);
        // Headers
        const headers = new Headers({
            'Content-Type': 'application/json; charset=utf-8',
            Accept: 'application/json',
        });
        if (request.isMultipartFormData === true) {
            headers.delete('Content-Type');
        }
        // construct a request
        const requestOptions = {
            method: request.method,
            headers,
            cache: 'default',
            body: this.getBody(request, request.files),
        };
        if (request.cors === undefined || request.cors === true) {
            requestOptions.mode = 'cors';
            requestOptions.credentials = 'include';
        }
        // await the response
        const response = await fetch(url, requestOptions);
        if (response.status === 500) {
            throw Error(`${response.status}: ${response.statusText}`);
        }
        const data = (await response.json());
        return data;
    }
    async safeRequest(request) {
        try {
            const response = await this.request(request);
            return response;
        }
        catch {
            return null;
        }
    }
    getUrl(request) {
        let url = `/${request.slug}`;
        if (request.controller && request.controller.length > 0) {
            url = `/api/${request.controller}/${request.slug}`;
        }
        let isStorybook = false;
        if (!isSSR()) {
            if ((window.location.host === 'styleguide.groundforce.netcprev.co.uk' ||
                window.location.port === '4000' ||
                window.location.port === '4001') &&
                process.env.API_URL) {
                isStorybook = true;
            }
        }
        if (request.baseUrl) {
            url = `${request.baseUrl}${url}`;
        }
        else if (isStorybook) {
            url = `${process.env.API_URL}${url}`;
        }
        if ((request.method === 'GET' || request.method === 'DELETE') && request.params) {
            url += '?';
            url += querystring.stringify({ ...request.params });
        }
        return url;
    }
    getBody(request, files) {
        if (request.method === 'GET') {
            return null;
        }
        if (request.isMultipartFormData === true) {
            const formData = new FormData();
            formData.append('params', JSON.stringify(request.params));
            if (files && files.length > 0) {
                for (let index = 0; index < files.length; index++) {
                    const file = files[index];
                    formData.append(`files-${index}`, file);
                }
            }
            return formData;
        }
        return JSON.stringify(request.params);
    }
}
export default new ApiService();
