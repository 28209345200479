import { useEffect, useRef } from 'react';
/**
 * Use the IntersectionObserver to track visibility of an element in the viewport.
 * @param {IntersectionObserverCallback} callback
 * @param {IntersectionObserverInit} options
 * @returns {UseIntersectionObserverReturn}
 */
function useIntersectionObserver(callback, options = {}) {
    const observerRef = useRef(null);
    const rootRef = useRef(null);
    const elementRef = useRef(null);
    useEffect(() => {
        if (!elementRef.current) {
            return undefined;
        }
        observerRef.current = new IntersectionObserver(callback, {
            root: rootRef.current,
            ...options,
        });
        observerRef.current.observe(elementRef.current);
        return () => {
            if (observerRef.current === null) {
                return;
            }
            observerRef.current.disconnect();
        };
    }, [callback, options]);
    return { elementRef, observerRef, rootRef };
}
export default useIntersectionObserver;
