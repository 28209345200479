import React from 'react';
import S from './Checkbox.styles';
import noop from 'lodash/noop';
const Checkbox = ({ value, onKeyDown = (e) => {
    if (e.key.toLowerCase() === 'enter') {
        e.preventDefault(); // Stops weird behaviour
    }
}, onClick = () => noop(), ...props }) => {
    const onChange = (e) => {
        if (props.onChange) {
            props.onChange(e);
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(S.Input, { ...props, type: "checkbox", checked: value, onChange: (e) => onChange(e), onKeyDown: onKeyDown }),
        !props.htmlLabel && (React.createElement(S.Label, { onClick: () => onClick && onClick(!value), htmlFor: props.id },
            props.label,
            " ",
            props.required ? React.createElement("span", null, "*") : null)),
        !!props.htmlLabel && (React.createElement(S.Label, { onClick: () => onClick && onClick(!value), htmlFor: props.id, dangerouslySetInnerHTML: { __html: props.label } }, props.required ? React.createElement("span", null, "*") : null))));
};
export default Checkbox;
